@import "../scss/variable";

.ant-select-item-option-content,
.ant-select-selection-item {
  text-align: left;
  font: normal normal 600 14px/20px sans-serif;
  letter-spacing: 0px;
  color: $--unnamed-color-24242600;
}

.ant-tag.ant-tag-has-color {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  transition: font-size .3s, line-height .3s, height .3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
  -webkit-padding-end: 4px;
  padding-inline-end: 4px;
  color: #000;
  font-size: 14px !important;
  * {
    color: #000;
    margin-top: 3px;
  }
}

.ant-input{
  height: 32px;
}