.statistical {
  &__box {
    height: 20px;
    width: 20px;
  }

  &__wrap {
    width: 100%;
    overflow-x: auto;
  }

  &__chart {
    width: 3000px;
    max-width: 3000px;
  }
}

@media all and (max-width: 281px) { 
  .mobie_text{
    flex-direction: column;
    align-items: start !important;
  }
}