.uploadDetailBooking {

  &-container {
    width: 100%;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & span,
  & .ant-upload {
    display: block;
    width: 100%;
  }
}

.vehicleRecords {

 &-box {
  background: #fff;
 }

}

.StatusIndicator {

  &-boxIcon {
    display: flex;

    & span {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
    }

  }
}

.modalClose {
  & .ant-modal-close-x {
    height: 30px;
    width: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}