.chat {
  margin: -12px -16px -19px;
  height: auto;

  &__title {
    box-sizing: border-box;
  }

  &__empty {
    &__text {
      p {
        color: #8C8C8C;
      }
    }
  }

  &__item {
    padding: 15px 20px;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    cursor: pointer;

    &__top {
      flex: 1;
    }

    &__wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__message,
    &__date {
      margin-left: 36px;
      margin-top: 6px;
      
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #8C8C8C;
        word-break: break-all; 
      }
    }

    &__date {
      margin: 0px;
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__send {
      width: 6px;
      height: 6px;
      background: #F5222D;
      border-radius: 50%;
    }

    &:hover,
    &.active {
      background: #F0F5FF;
    }
  }

  &__list {
    overflow-y: auto;
    flex: 1;
  }

  &__wrap {
    height: calc(100vh - 140px);
  }

  &__col {
    height: calc(100vh - 140px);
  }

  &__box {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__more {
    height: 50px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      color: #1677ff;
    }

    &__empty {
      height: 50px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1140px) {
  .chat {
    &__wrap {
      height: auto;
    }
  }
}