.ant-modal-content {
    .ant-modal-header {
        border-radius: 10px;

        .ant-modal-title {
            text-align: left;
            font: normal normal bold 20px/20px sans-serif;
            letter-spacing: 0px;
            color: $--unnamed-color-24242600;
        }
    }
}

.ant-btn {

    label,
    span {
        text-align: left;
        font: normal normal 600 14px/20px sans-serif;
        letter-spacing: 0px;
    }
}

.blue_button {
    background-color: var(--main-primary);

    span,
    label {
        color: $--unnamed-color-ffffff;
        width: 100%;
        height: 100%;
    }

    &:hover {
        background-color: var(--main-primary);
        border: none;
    }

    &:focus {
        background-color: var(--main-primary);
        border: none;
    }
}

.black_button {
    background-color: $--unnamed-color-24242;

    height: 100%;

    span,
    label {
        color: $--unnamed-color-ffffff;
        width: 100%;
        height: 100%;
    }

    &:hover {
        background-color: $--unnamed-color-24242;
        border: none;
    }

    &:focus {
        background-color: $--unnamed-color-24242;
        border: none;
    }
}