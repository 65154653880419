    @media(max-width: 920px) {
      .phonebook__body {
        margin-top: 5px;
      }
    }

  @media(max-width: 426px) {
  & .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
}
