.chat__info {
  &__header {
    padding: 12px 16px 20px;
    border-bottom: 1px solid #BFBFBF;
  }

  &__book {
    padding: 20px 16px;

    &__action {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__btn.ant-btn-background-ghost.ant-btn-primary {
      display: flex;
      align-items: center;
    }

    &__title {
      font-weight: 700;
    }

    &__list {
      margin-top: 20px;
      height: 300px;
      overflow-y: auto;
    }
  }

  &__card {
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 12px 16px;

    &__title.ant-typography {
      color: #8C8C8C;
      font-weight: 700;
      text-align: left;
      display: block;
    }

    &__list {
      margin-top: 16px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
}

.chat_itemSchedule {
  padding: 12px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  background: #FFFFFF;

  &_header {

    &_info {
      flex: 1;

      & span {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px
      }
    }

    &_type {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }

    &_action {
      width: 80px;
    }
  }

  &_footer {
    justify-content: space-between;
    align-items: center;

    &_left {
      width: 107px;
    }

    &_status {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }

}