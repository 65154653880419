.telegram-noti{
    .switch{
        display: block;
    }
    .expand-status{
        width: 107px;
        margin-left: 20px;
    }
    .telegram-noti_content{
        margin-top: 10px;
    }
    .ant-checkbox-wrapper{
        margin-left: unset !important;
        display: flex !important;
        margin-bottom: 10px;
    }
    .telegram-check{
        margin-bottom: 10px !important;
    }
}

.vmg_textmodal{
    margin-top: 15px;
}