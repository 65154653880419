.tagVehicle {
  width: 100%;
  height: 44px;
  margin: 5px;
  border-radius: 4px;
  font-size: 17px;
  &_box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 600;
  }
}