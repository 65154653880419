@import "../../assets/scss/variable";

.list_schedules {
    .list_schedules__title {
        text-align: center;
        font: normal normal bold 24px/20px sans-serif;
        letter-spacing: 0px;
        color: #000000;
        text-transform: uppercase;
        padding-top: 58px;
        padding-bottom: 38px;
    }

    &__select {
        padding: 0 !important;

        &_item>* {
            @media(min-width: 1200px) {
                width: 80% !important;
            }
        }
    }

    &__box {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    .handled_customer {
        background-color: rgba(230, 247, 255, 0.5);
    }

    .handle_icon {
        height: 20px;
        margin-top: 5px;
        fill: #8C8C8C;
    }

    .handled_customer {
        .handle_icon {
            fill: #1890FF;

        }
    }
}
.tagVehicle {
    width: 100%;
    height: 44px;
    margin: 5px;
    border-radius: 4px;

    &_box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: 600;
    }
}
.button--text {
    margin-right: 10px;
    color: #1890FF;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}
  .updoad {
    display: flex;
  
    .lefta {
      margin-right: 77px;
      overflow: hidden;
  
      .textex {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
      }
  
      .labela {
        position: relative;
        width: 148px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
  
        span {
          position: absolute;
          bottom: 6px;
          left: 17px;
        }
  
        label {
          position: absolute;
          bottom: 3px;
          right: 12px;
        }
      }
  
      .texta {
        color: #1890ff;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  
    .righta {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 10px;
      }
  
      .untra {
        position: relative;
        width: 148px;
        height: 32px;
        background: #1890ff;
        border: 1px solid #d9d9d9;
  
        span {
          position: absolute;
          bottom: 6px;
          left: 17px;
        }
  
        label {
          color: #ffffff;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          position: absolute;
          bottom: 3px;
          right: 20px;
        }
      }
    }
  }

  .ant-tag{
    font-size: $text-small-fs !important;
    margin-bottom: 5px !important;
  }

  @media (max-width: 576px) {
    .list_schedules {
      &__box {
        & .ant-space-item {
            width: 100%;
        }
      }
    }

    .updoad {
        flex-direction: column;
        align-items: center;

        & .lefta {
            margin-right: 0px !important;
            margin-bottom: 10px;
            width: 100%;
        }

        & .righta {
          width: 100%;
        }
    }
  }

  @media(max-width: 281px) { 
    .ant-select{
      min-width: 228px !important;
    }
    .mobies {
      padding: 8px !important;
    }
  }