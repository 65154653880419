.ant-typography.section-title {
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
  text-align: left;
}

.customerSettings {
  &-day {
    font-weight: 600;
  }

}