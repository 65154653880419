.ant-dropdown {
    .ant-dropdown-menu {
        .ant-dropdown-menu-item {
            .ant-dropdown-menu-title-content {
                text-align: left;
                font: normal normal 600 14px/20px sans-serif;
                letter-spacing: 0px;
                color: #242426;
                padding: 5px;
            }
        }
    }
}