.ant-layout {
  min-height: 100vh;

  .content {
    background-color: #f0f2f5;
    padding: 10px;

    &>div {
      min-height: calc(100vh - 80px);
      background-color: #fff;
      padding: 12px 16px 19px;
    }
  }
}