@import '../../assets/scss/variable';

.schedule_setting {
    width: 100%;

    .schedule_setting__title {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        text-align: left;
        font-size: $text-large-fs;
        font-weight: bold;
        letter-spacing: 0px;
        color: #000000;
        text-transform: uppercase;
    }

    &__section_title {
        div {
            background-color: #FAFAFA;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            padding: 16px;



            label {
                text-align: left;
                font: normal normal bold 16px/20px sans-serif;
                letter-spacing: 0px;
                color: #242426;
            }

            &:first-child {
                label {
                    border-right: 1px solid rgba(0, 0, 0, 0.06);
                    width: 100%;
                }
            }
        }

        &:last-child {
            border-right: 2px solid rgba(0, 0, 0, 0.06);
        }
    }

    .schedule_setting__body {
        width: 100%;
        justify-content: space-evenly;
        flex-direction: row;
        display: flex;

        &__timeline {
            background: rgba(255, 255, 255, 0.5);
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            padding: 16px;

            &-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);

                &.divider {
                    border-right: 2px solid rgba(0, 0, 0, 0.06);
                }

                .ant-row.ant-form-item {
                    margin: 0;
                }
            }
        }
    }
    &__table {
        width: 100%;
        max-width: 1400px;
    }

    .ant-table-tbody .ant-table-cell {
        padding: 20px 16px;
        .ant-form-item{
            margin-bottom: 0;
        }
    }
    
    & .ant-card .ant-card-body {
        padding: 13px !important;
    }
}
.modal-comfirm{
    .ant-modal-content .ant-modal-header{
        padding:16px 40px 16px 24px;
    }
}
@media(max-width: 400px) { 
    .schedule_setting{
        .schedule_setting__title {
            display: grid;
            gap: 15px;
        }
    }
  }